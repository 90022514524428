import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { Route, useRouteMatch, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state'
import BigNumber from 'bignumber.js'
import { useHistory } from 'react-router'
import { useWeb3React } from '@web3-react/core'
import { RowType, Toggle, Text, Flex, HelpIcon, useTooltip, LinkExternal } from '@hurricaneswap/uikit'
import styled from 'styled-components'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import { useFarms, usePriceCakeBusd, useGetApiPrices } from 'state/hooks'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmUserDataAsync } from 'state/actions'
import usePersistState from 'hooks/usePersistState'
import { Farm } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { getFarmApr } from 'utils/apr'
import { orderBy } from 'lodash'
import { getAddress } from 'utils/addressHelpers'
import isArchivedPid from 'utils/farmHelpers'
import { latinise } from 'utils/latinise'
import PageHeader from 'components/PageHeader'
import { fetchFarmsPublicDataAsync, setLoadArchivedFarmsData } from 'state/farms'
// import useFetchPairFromLp from 'state/farms/fetchPairFromLp'
// import useFetchLpOfPid from 'state/farms/fetchLpPairs'
import Select, { OptionProps } from 'components/Select/Select'
import SubSelect from 'components/SubSelect/SubSelect'
// import farmLps from 'config/constants/farms'
import FarmCard, { FarmWithStakedValue } from './components/FarmCard/FarmCard'
import Table from './components/FarmTable/FarmTable'
import FarmTabButtons from './components/FarmTabButtons'
import SearchInput from './components/SearchInput'
import { RowProps } from './components/FarmTable/Row'
import ToggleView from './components/ToggleView/ToggleView'
import { DesktopColumnSchema, ViewMode } from './components/types'
import useMobile from "../../hooks/useMobile";
import Footer from "../../components/PageFooter"

const getTitleColor = ({ theme, isChoose }) => {
  let color
  if (isChoose) {
    color = theme.colors.text
  } else if (theme.isDark) {
    color = 'rgba(255, 255, 255, 0.45)'
  } else {
    color = 'rgb(77 60 85 / 51%)'
  }

  return color
}

const getTabColor = ({ theme, isChoose }) => {
  let style
  if (isChoose) {
    style = '4px solid #6425C5'
  } else if (theme.isDark) {
    style = '4px solid rgba(255, 255, 255, 0.45)'
  } else {
    style = '4px solid rgb(77 60 85 / 51%)'
  }
  return style
}

const Title = styled(Text) <{ isChoose: boolean }>`
  font-family: GTTextBold;
  font-size: 2.5em;
  line-height: 46px;
  text-align: center;
  margin-bottom: 10px;
  color: ${getTitleColor}
`
const Tab = styled.div<{ isChoose: boolean, ifMobile: boolean }>`
  width: ${({ ifMobile }) => ifMobile ? 'calc(50vw - 24px)' : '200px'} ;
  border-bottom: ${getTabColor};
  cursor:pointer

`
const Text1 = styled(Text)`
  color: ${({ theme }) => theme.isDark ? '#FFFFFF' : 'rgba(77, 60, 85, 0.65)'};
`

const ControlContainer = styled.div<{ ifMobile: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 0px;
    margin-bottom: 0;
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 32px;
  
  >div:first-of-type{
    width: 56px;
    height: 32px;
    div{
      width: 24px;
      height: 24px;
      top: auto;
      left: 5px;
    }
  }
  
   input:checked{
    +div{
      left: calc(100% - 30px)!important;
    }
  }
  

  ${Text} {
    margin-left: 8px;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const FilterContainer = styled.div<{ ifMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content:  ${({ ifMobile }) => ifMobile ? 'space-between' : 'center'};
  width: 100%;
  padding: 8px 0px;
  margin: 5px 0;
  padding: ${({ ifMobile }) => (ifMobile ? '0 10px' : '0')};

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  };
    padding-left: 0px;

    padding-right: 0px
       
`
const StyledLinkExternal = styled(LinkExternal)`
  color: #5B8DEF;
  svg{
    fill: #5B8DEF;
  }
  margin-top:0px;
  margin-bottom:0px;
`
const ViewControls = styled.div<{ ifMobile: boolean }>`

  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  
  > div:nth-child(1){
    order: ${({ ifMobile }) => ifMobile ? '2' : '1'};
    // padding-left: ${({ ifMobile }) => ifMobile ? '10px' : 'none'};
    padding-left: 0px
     }
  > div:nth-child(2){
    order: ${({ ifMobile }) => ifMobile ? '3' : '2'};
    
    // padding-right: ${({ ifMobile }) => ifMobile ? '10px' : 'none'};
    padding-right: 0px
       }
  > div:nth-child(3){
    order: ${({ ifMobile }) => ifMobile ? '1' : '3'};
    width: ${({ ifMobile }) => ifMobile ? '100%' : 'none'};
    // padding-left: ${({ ifMobile }) => ifMobile ? '10px' : 'none'};
    padding-left: 0px
    }

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }
`


const FarmSection = styled.div`
  padding-top: 59px;
`

const CustomizedToggle = styled(Toggle)`
  height: 32px;
`

const NUMBER_OF_FARMS_VISIBLE = 12

const Farms: React.FC = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const ifMobile = useMobile()
  const { data: farmsLP, userDataLoaded } = useFarms()
  const cakePrice = usePriceCakeBusd()
  const [query, setQuery] = useState('')
  // const [chosenChain, SetChosenChain] = useState('ALL')
  const [chosenToken, setChosenToken] = useState('None')
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, 'hurricane_farm_view')
  const { account } = useWeb3React()
  const [sortOption, setSortOption] = useState('hot')
  const prices = useGetApiPrices()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchFarmUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const isArchived = pathname.includes('archived')
  // const isInactive = pathname.includes('history')
  const [isInactive, setIsInactive] = useState(false)
  const isActive = !isInactive && !isArchived



  // Users with no wallet connected should see 0 as Earned amount
  // Connected users should see loading indicator until first userData has loaded
  const userDataReady = !account || (!!account && userDataLoaded)

  const [stakedOnly, setStakedOnly] = useState(!isActive)
  /* useEffect(() => {
    setStakedOnly(!isActive)
  }, [isActive]) */

  useEffect(() => {
    // Makes the main scheduled fetching to request archived farms data
    dispatch(setLoadArchivedFarmsData(isArchived))

    // Immediately request data for archived farms so users don't have to wait
    // 60 seconds for public data and 10 seconds for user data
    if (isArchived) {
      dispatch(fetchFarmsPublicDataAsync())
      if (account) {
        dispatch(fetchFarmUserDataAsync(account))
      }
    }
  }, [isArchived, dispatch, account])
  // const activeFarms = farmsLP.filter((farm) => !isArchivedPid(farm.pid) && farm.multiplier !== '0X')
  // const inactiveFarms = farmsLP.filter((farm) => farm.pid !== 0 && farm.multiplier === '0X' && !isArchivedPid(farm.pid))
  // V2 farms, set to be removed once unstaked pool 合约不替换换的话 那个只换lptoken的话 isArchivedPid 里的参数要改一下
  const inactiveFarms = farmsLP.filter((farm) => farm.multiplier === '0X' && !isArchivedPid(farm.pid))
  const archivedFarms = farmsLP.filter((farm) => isArchivedPid(farm.pid))
  const allFarms = farmsLP.filter((farm) => !isArchivedPid(farm.pid))
  const activeFarms = allFarms.filter((farm) => farm.multiplier !== '0X')

  const stakedOnlyFarms = activeFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedInactiveFarms = inactiveFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedArchivedFarms = archivedFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )
  const stakedAllFarms = allFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const farmsList = useCallback(
    (farmsToDisplay: Farm[]): FarmWithStakedValue[] => {

      let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
        if (!farm.lpTotalInQuoteToken || !prices) {
          return farm
        }


        const quoteTokenPriceUsd = prices[getAddress(farm.quoteToken.address).toLowerCase()]
        const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(quoteTokenPriceUsd)
        // if (farm.lpSymbol === 'sHCT'){
        //  console.log(farm.lpTotalInQuoteToken.toString(),quoteTokenPriceUsd)
        // }
        // if (parseFloat(farm.userData.stakedBalance) >0){
        // console.log('shuju',farm.userData.stakedBalance/farm.lpTotalSupply * totalLiquidity )}
        // @ts-ignore
        const userLiquidity = farm.userData.stakedBalance / farm.lpTotalSupply * totalLiquidity

        const apr = isActive ? getFarmApr(farm.poolWeight, cakePrice, totalLiquidity) : 0
        return { ...farm, apr, liquidity: totalLiquidity }
      })

      if (query) {
        const lowercaseQuery = latinise(query.toLowerCase())
        farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
          return latinise(farm.lpSymbol.toLowerCase()).includes(lowercaseQuery)
        })
      }
      return farmsToDisplayWithAPR
    },
    [cakePrice, prices, query, isActive],
  )

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const loadMoreRef = useRef<HTMLDivElement>(null)

  const [numberOfFarmsVisible, setNumberOfFarmsVisible] = useState(NUMBER_OF_FARMS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  // const [isPool, setIsPool] = useState(false)
  const isPool = pathname.includes('pool')
  const farmsStakedMemoized = useMemo(() => {
    let farmsStaked = []

    const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      switch (sortOption) {
        case 'apr':
          // NaN
          // return orderBy(farms, (farm: FarmWithStakedValue) => farm.apr, 'desc')
          return farms.sort((a, b) => {
            return (b?.apr || 0) - (a?.apr || 0)
          })
        case 'multiplier':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0),
            'desc',
          )
        case 'earned':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
            'desc',
          )
        case 'liquidity':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
        default:
          return farms
      }
    }

    if (isActive) {
      farmsStaked = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
    }
    if (isInactive) {
      farmsStaked = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
    }
    if (isArchived) {
      farmsStaked = stakedOnly ? farmsList(stakedArchivedFarms) : farmsList(archivedFarms)
    }

    // const [chosenChain, SetChosenChain] = useState('ALL')
    // const [chosenToken, SetChosenToken] = useState('ALL')

    /* if (chosenChain === 'BSC'){
       farmsStaked = farmsStaked.filter(farm => farm.chain === 'BSC' )
     } 
     if  (chosenChain === 'AVAX'){
       farmsStaked = farmsStaked.filter(farm => farm.chain === 'AVAX' )
     } */

    const farmsStakeAll = stakedOnly ? farmsList(stakedAllFarms) : farmsList(allFarms)
    if (chosenToken === 'HCT') {
      farmsStaked = farmsStakeAll.filter(farm => farm.lpSymbol.includes('HCT'))
    }
    if (chosenToken === 'AVAX') {
      farmsStaked = farmsStakeAll.filter(farm => farm.lpSymbol.includes('AVAX'))
    }
    if (chosenToken === 'Stablecoin') {
      farmsStaked = farmsStakeAll.filter(farm => farm.lpSymbol.includes('USDT') || farm.lpSymbol.includes('USDC'))
    }
    if (chosenToken === 'aToken') {
      farmsStaked = farmsStakeAll.filter(farm => farm.lpSymbol.includes('-a'))
    }
    if (chosenToken === 'ALL') {
      farmsStaked = farmsStakeAll
    }
    if (isPool) {
      farmsStaked = farmsStaked.filter(farm => farm.isPool)
    } else {
      farmsStaked = farmsStaked.filter(farm => !farm.isPool)
    }


    return sortFarms(farmsStaked).slice(0, numberOfFarmsVisible)
  }, [
    sortOption,
    activeFarms,
    farmsList,
    isPool,
    inactiveFarms,
    archivedFarms,
    allFarms,
    isActive,
    isInactive,
    isArchived,
    stakedArchivedFarms,
    stakedInactiveFarms,
    stakedAllFarms,
    stakedOnly,
    stakedOnlyFarms,
    numberOfFarmsVisible,
    chosenToken,
  ])

  useEffect(() => {
    const showMoreFarms = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfFarmsVisible((farmsCurrentlyVisible) => farmsCurrentlyVisible + NUMBER_OF_FARMS_VISIBLE)
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMoreFarms, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [farmsStakedMemoized, observerIsSet])

  const rowData = farmsStakedMemoized.map((farm) => {
    const { token, quoteToken } = farm
    const tokenAddress = token.address
    const quoteTokenAddress = quoteToken.address
    const lpLabel = farm.lpSymbol && farm.lpSymbol.split(' ')[0].replace('HURRICANE', '')
    const lpLogo = farm?.lpLogo
    const row: RowProps = {
      apr: {
        value: farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2 }),
        multiplier: farm.multiplier,
        lpLabel,
        tokenAddress,
        quoteTokenAddress,
        cakePrice,
        originalValue: farm.apr,
      },
      farm: {
        image: farm.lpSymbol.split(' ')[0].toLocaleLowerCase(),
        logoURI: farm?.quoteToken?.logoURI,
        label: lpLabel,
        pid: farm.pid,
      },
      earned: {
        earnings: getBalanceNumber(new BigNumber(farm.userData.earnings)),
        pid: farm.pid,
      },
      liquidity: {
        liquidity: farm.liquidity,
      },
      multiplier: {
        multiplier: farm.multiplier,
      },
      details: farm,
      chain: { chain: farm.chain },
      farmStatus: { farmStatus: inactiveFarms.some(item => item.pid === farm.pid) ?  'Finished' : 'Live' }
    }

    return row
  })

  const renderContent = (): JSX.Element => {

    if (viewMode === ViewMode.TABLE && rowData.length) {
      const columnSchema = DesktopColumnSchema

      const columns = columnSchema.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        sort: (a: RowType<RowProps>, b: RowType<RowProps>) => {
          switch (column.name) {
            case 'farm':
              return b.id - a.id
            case 'apr':
              if (a.original.apr.value && b.original.apr.value) {
                return Number(a.original.apr.value) - Number(b.original.apr.value)
              }
              return 0
            case 'earned':
              return a.original.earned.earnings - b.original.earned.earnings
            default:
              return 1
          }
        },
        sortable: column.sortable,
      }))
      // console.log('farm数据', userDataReady,rowData,columns,)
      return <Table data={rowData} columns={columns} userDataReady={userDataReady} />
    }

    return (
      <div style={{ marginBottom: '32px', marginTop: '20px' }}>
        <FlexLayout>
          <Route exact path={`${path}`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} cakePrice={cakePrice} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/history`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} cakePrice={cakePrice} account={account} removed />
            ))}
          </Route>
          <Route exact path={`${path}/archived`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} cakePrice={cakePrice} account={account} removed />
            ))}
          </Route>
        </FlexLayout>
      </div>
    )
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const handleSelectOptionChange = (first, option): void => {
    if (first.label === 'Token') {
      setChosenToken(option.label)
    }
    if (first.label === 'Status'){
      setIsInactive(option.value !== 'live')
      setChosenToken('None')
    }
  }

  const tooltipContent = (
    <div>
      {t('The multiplier represents the proportion of HCT rewards received by each farm, as a proportion of the HCT produced by each block.')}
      <br />
      {t('For example: if a 1x farm received 1 HCT per block, a 10x farm will receive 10 HCT per block.')}
      <br />
      {t('The multiplier is adjusted according to the TVL, liquidity and activity, and is already included in the APR calculations for all the farms.')}
    </div>
  )
  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, {
    placement: 'top-end',
    tooltipOffset: [50, 10],
  })

  return (
    <FarmSection id='Farms' >
      <PageHeader background='transparent'>
        <Flex alignItems='center' flexDirection='column'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tab isChoose={!isPool} onClick={() => history.push('/')} ifMobile={ifMobile}>
              <Title isChoose={!isPool} >
                {t('Farm')}
              </Title>
            </Tab>
            <Tab isChoose={isPool} onClick={() => history.push('/pool')} ifMobile={ifMobile}>
              <Title isChoose={isPool} >
                {t('Pool')}
              </Title>
            </Tab>
            {/* <HelpIcon color="textSubtle" style={{ marginLeft: '15px' }} /> */}
          </div>
          {/* tooltipVisible && tooltip */}
          <Text1 textAlign='center' mt={16} mb={20} fontSize='1.5em' fontWeight='200' maxWidth='642px' width='90vw'>
            {t('Deposit single token to earn, no impermanent loss')}
          </Text1>
          <StyledLinkExternal href="https://farm-v1.hurricaneswap.com/" mt='40px' mb={ifMobile ? '50px' : '122px'} >V1 Version</StyledLinkExternal>
        </Flex>
      </PageHeader>
      <Page>
        <ControlContainer ifMobile={ifMobile}>
          <ViewControls ifMobile={ifMobile}>
            <LabelWrapper>
              <SubSelect
                options={[
                  {
                    label: 'Token',
                    value: 'token',
                    children: [
                      {
                        label: 'ALL',
                        value: 'ALL'
                      },
                      {
                        label: 'HCT',
                        value: 'HCT'
                      },
                      {
                        label: 'Stablecoin',
                        value: 'Stablecoin'
                      },
                      {
                        label: 'AVAX',
                        value: 'AVAX'
                      },
                      {
                        label: 'aToken',
                        value: 'aToken'
                      },
                    ]
                  },
                  {
                    label: 'Status',
                    value: 'status',
                    children: [
                      {
                        label: 'Live',
                        value: 'live'
                      },
                      {
                        label: 'Finished',
                        value: 'finished'
                      },
                    ]
                  },

                ]}
                onChange={handleSelectOptionChange}
              />
            </LabelWrapper >
            <ToggleWrapper style={{ marginLeft: ifMobile ? '0px' : '20px' }}>
              <CustomizedToggle checked={stakedOnly} onChange={() => setStakedOnly(!stakedOnly)} scale="sm" />
              <Flex height='100%' alignItems='center'>
                <Text fontSize={ifMobile ? '12px' : '16px'} > {t('Staked only')}</Text>
              </Flex>
            </ToggleWrapper>
            <LabelWrapper style={{ marginLeft: ifMobile ? '0px' : '20px' }}>
              <SearchInput onChange={handleChangeQuery} />
            </LabelWrapper>
          </ViewControls>
          <FilterContainer ifMobile={ifMobile}>
            <LabelWrapper>

              <Select
                options={[
                  {
                    label: 'Hot',
                    value: 'hot',
                  },
                  {
                    label: 'APR',
                    value: 'apr',
                  },
                  {
                    label: 'Multiplier',
                    value: 'multiplier',
                  },
                  {
                    label: 'Earned',
                    value: 'earned',
                  },
                  {
                    label: 'Liquidity',
                    value: 'liquidity',
                  },
                ]}
                onChange={handleSortOptionChange}
              />
            </LabelWrapper>

            <Flex ml='20px'>
              <ToggleView viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} />
            </Flex>
          </FilterContainer>
        </ControlContainer>
        {renderContent()}
        <div ref={loadMoreRef} />
      </Page>
    </FarmSection>
  )
}

export default Farms
