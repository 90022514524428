import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'HurricaneSwap',
  description:
    'Flip HURRICANE and stake them with HurricaneSwap, the best decentralized exchange on Avalanche Smart Chain.',
  image: 'https://i.loli.net/2021/08/29/yVGFeJkSvUaMRHP.png',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Farms | HurricaneSwap',
  },
  '/farms': {
    title: 'Farms | HurricaneSwap',
  }
}
