import React from 'react'
import styled from 'styled-components'
import { useFarmUser } from 'state/hooks'
import { Text, Image, Flex } from '@hurricaneswap/uikit'

export interface FarmProps {
  label: string
  pid: number
  image: string
  logoURI?: any
  farmImage?: any
  secondFarmImage?: any
  isPool?: boolean
  lpLogo?: string
}

const IconBImage = styled(Image)`
position: absolute;
left: -8px;
top: -8px;
background: ${({theme}) => theme.isDark ? '#1D142B' :'#fff'};

width: 30px;
height: 30px;

display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
border: ${({theme}) => theme.isDark ? '1px solid #8D80D5' : 'none' };
box-shadow: 0px 2px 8px rgba(100, 37, 197, 0.1);

img{
  display: inline-block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: relative;
}
`

const IconImage = styled(Image)`
  // padding: 16px;
  background: ${({theme}) => theme.isDark ? '#1D142B' :'#fff'};

  width: 42px;
  height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgba(100, 37, 197, 0.1);
  border: ${({theme}) => theme.isDark ? '1px solid #8D80D5' : '1px solid #EFEBFF' };

  img{
    border-radius: 50%;
    display: inline-block;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    position: relative;
  }

`

const Container = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 32px;
  }
`


const ResponsiveText = styled(Text)`
  font-size: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 20px;
  }
`

const Farm: React.FunctionComponent<FarmProps> = ({ farmImage, secondFarmImage,  label, pid ,isPool, lpLogo}) => {
   const { stakedBalance } = useFarmUser(pid)
  // const { t } = useTranslation()
  // const rawStakedBalance = getBalanceNumber(stakedBalance)
  const handleRenderFarming = (): JSX.Element => {
    // if (rawStakedBalance) {
    //   return (
    //     <Text color="secondary" fontSize="12px" bold>
    //       {t('FARMING')}
    //     </Text>
    //   )
    // }

    return null
    
  }
  return (
    <Container>
      {/* <IconImage src={`/images/farms/${image}.svg`} alt="icon" width={40} height={40} mr="8px" /> */}
      <Flex position='relative' pl='4px'>
        {
          !isPool ?
        <IconBImage src={secondFarmImage} alt="icon" width={40} height={40}/>
        : null
        }   
      {   !isPool ?
        <IconImage src={farmImage} alt="icon" width={40} height={40} />
        :
        <IconImage src={lpLogo} alt="icon" width={40} height={40} />
      }
        
      </Flex>
      <div>
        {handleRenderFarming()}
        <ResponsiveText ml='12px' bold>{label}</ResponsiveText>
      </div>
    </Container>
  )
}

export default Farm
