import React from 'react'
import styled from 'styled-components'
import { Tag, Flex, Image } from '@hurricaneswap/uikit'


export interface ExpandableSectionProps {
  lpLabel?: string
  multiplier?: string
  isCommunityFarm?: boolean
  farmImage?: string
  tokenSymbol?: string
  secondFarmImage?: string
  chain?: string
  isPool?: boolean
  lpLogo?: string
}
const StyleHeading = styled.div`
font-family: GTText;
font-style: normal;
font-weight: bold;
font-size: 0.75em;
line-height: 38px;
/* or 136% */
display: flex;
align-items: center;
text-align: right;
color: ${({theme}) => theme.isDark ? '#fff':'#4D3C55'}
`
const Wrapper = styled(Flex)`
  svg {
    margin-right: 4px;
  }
`

const MultiplierTag = styled(Tag)`
  background: #8D80D5;
  margin-left: 8px;
  min-width: 52px;
  min-height: 28px;
  justify-content: center;
  margin-top: 6px;
`
const ChainTag = styled(Tag)`
  background: transparent;
  border: 1px solid #8D80D5;
  color: #8D80D5;
  min-width: 52px;
  min-height: 28px;
  justify-content: center;
  margin-top: 6px;
`

const IconBImage = styled(Image)`
background: ${({theme}) => theme.isDark ? '#1D142B' :'#fff'};
// position: absolute;
// left:20px;
width: 38px;
height: 38px;

display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
border: ${({theme}) => theme.isDark ? '2px solid #8D80D5' : 'none' };
box-shadow: 0px 2px 8px rgba(100, 37, 197, 0.1);

img{
  display: inline-block;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  position: relative;
}
`

const IconImage = styled(Image)`
  position: absolute;
  left: 12px;
  top: 8px;
  // padding: 16px;
  background: ${({theme}) => theme.isDark ? '#1D142B' :'#fff'};

  width: 145%;
  height: 145%;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({theme}) => theme.isDark ? '2px solid #8D80D5' : '1px solid #EFEBFF' };
  box-shadow: 0px 2px 8px rgba(100, 37, 197, 0.1);  

  img{
    border-radius: 50%;
    display: inline-block;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: relative;
  }

`
const SingleImage = styled(Image)`
  position: absolute;
  left: -4px;
  top: 0px;
  // padding: 16px;
  background: ${({theme}) => theme.isDark ? '#1D142B' :'#fff'};

  width: 3.8em;
  height: 3.8em;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({theme}) => theme.isDark ? '2px solid #8D80D5' : '1px solid #EFEBFF' };
  box-shadow: 0px 2px 8px rgba(100, 37, 197, 0.1);  

  img{
    border-radius: 50%;
    display: inline-block;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: relative;
  }

`

const CardHeading: React.FC<ExpandableSectionProps> = ({
  lpLabel,
  multiplier,
  farmImage,
  secondFarmImage,
  chain,
  isPool,
  lpLogo

}) => {
  return (
    <Wrapper justifyContent="space-between" alignItems="flex-start" mb="12px">
      {/* <Image src={`../../assets/images/farms/${farmImage}.svg`} alt={tokenSymbol} width={64} height={64} /> */}
      <Flex position='relative' alignItems='flex-start'>
        {
          !isPool? 
          ( <>
            <IconBImage src={secondFarmImage} alt="icon" width={40} height={40}/>
            <IconImage src={farmImage} alt="icon" width={64} height={64} />
            </>
          ):(

            <SingleImage src={lpLogo} alt="icon" width={64} height={64} />
          )
        }
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end" style={{fontSize:'28px'}}>
        <StyleHeading>{lpLabel.split(' ')[0]}</StyleHeading>
        <Flex justifyContent="center">
          {/* {isCommunityFarm ? <CommunityTag /> : <CoreTag />} */}
          <ChainTag variant="textSubtle">{chain}</ChainTag>
          <MultiplierTag variant="textSubtle">{multiplier}</MultiplierTag>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default CardHeading
