// @ts-nocheck
import React, { useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading, IconButton, AddIcon, MinusIcon, useModal } from '@hurricaneswap/uikit'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import useStake from 'hooks/useStake'
import useUnstake from 'hooks/useUnstake'
import { getBalanceNumber, } from 'utils/formatBalance'
import { BIG_TEN } from 'utils/bigNumber'
import DepositModal from '../DepositModal'
import WithdrawModal from '../WithdrawModal'


const UsdToShow = styled.div`
font-size: 14px;
color: ${({ theme }) => theme.colors.textSubtle};
line-height: 1.1;
font-weight:400
`

interface FarmCardActionsProps {
  stakedBalance?: BigNumber
  tokenBalance?: BigNumber
  tokenName?: string
  pid?: number
  addLiquidityUrl?: string
}

const IconButtonWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
  }
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
  stakedBalance,
  tokenBalance,
  tokenName,
  pid,
  addLiquidityUrl,
  farm
}) => {
  const { t } = useTranslation()
  const { onStake } = useStake(pid)
  const { onUnstake } = useUnstake(pid)
  const location = useLocation()

  const displayBalance = useCallback(() => {
    const stakedBalanceNumber = getBalanceNumber(stakedBalance)
    return stakedBalanceNumber
  }, [stakedBalance])
  
  let  userStakeUsdt 
  try{
    userStakeUsdt = farm.liquidity * stakedBalance /(new BigNumber(farm.lpTokenBalanceMC))
  }catch (e){
    userStakeUsdt = 0
  }
  
  // console.log(farm.liquidity.toNumber(),displayBalance(),(new BigNumber(farm.lpTotalSupply).div(BIG_TEN.pow(farm.quoteToken.decimals))))
  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} onConfirm={onStake} tokenName={tokenName} addLiquidityUrl={addLiquidityUrl} />,
  )
  const [onPresentWithdraw] = useModal(
    <WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={tokenName} />,
  )

  const renderStakingButtons = () => {
    return stakedBalance.eq(0) ? (
      <Button
        onClick={onPresentDeposit}
        disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
      >
        {t('Deposit')}
      </Button>
    ) : (
      <IconButtonWrapper>
        <IconButton variant="tertiary" onClick={onPresentWithdraw} mr="6px">
          <MinusIcon color="#FFF" width="14px" />
        </IconButton>
        <IconButton
          variant="tertiary"
          onClick={onPresentDeposit}
          disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
        >
          <AddIcon color="#FFF" width="14px" />
        </IconButton>
      </IconButtonWrapper>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Heading color={stakedBalance.eq(0) ? 'textDisabled' : 'text'}>{displayBalance() >0  && displayBalance()< 0.001 ? '<0.001' : displayBalance().toLocaleString()}
      { userStakeUsdt !== 0 ? 
      <UsdToShow>~${userStakeUsdt.toLocaleString()}</UsdToShow>:
      null
}
      </Heading>
      
      {renderStakingButtons()}
    </Flex>
  )
}

export default StakeAction
