import React, { useMemo } from 'react'
import { Currency, Token } from '@glhf-libs/sdk'
import styled from 'styled-components'
import { useWeb3React } from "@web3-react/core";
import { useAllTokens } from '../../hooks/useAllTokens'
import CurrencyList from "../CurrencyList";
import { useAllTokenBalances } from "../../hooks/useBalances";

const CoinListContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  color: ${({theme})=>(theme.colors.text)};
  background: ${({theme})=>(theme.isDark ? 'rgba(77, 60, 85, 0.45)' : 'rgba(141, 128, 213, 0.1)')};
  font-size: 12px!important;
`

const Wrapper = styled.div`
  padding: 12px 16px;
`

function balanceComparator(balanceA, balanceB) {
  if (balanceA && balanceB) {
    /* eslint-disable-next-line no-nested-ternary */
    return balanceA.greaterThan(balanceB) ? -1 : balanceA.equalTo(balanceB) ? 0 : 1
  }
  if (balanceA && balanceA.greaterThan('0')) {
    return -1
  }
  if (balanceB && balanceB.greaterThan('0')) {
    return 1
  }
  return 0
}

function getTokenComparator(balances) {
  return function sortTokens(tokenA, tokenB) {
    const balanceA = balances[tokenA.address]
    const balanceB = balances[tokenB.address]

    const balanceComp = balanceComparator(balanceA, balanceB)
    if (balanceComp !== 0) return balanceComp

    if (tokenA.symbol && tokenB.symbol) {
      // sort by symbol
      return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
    }
    /* eslint-disable-next-line no-nested-ternary */
    return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0
  }
}

function useTokenComparator(inverted: boolean): (tokenA: Token, tokenB: Token) => number {
  const balances = useAllTokenBalances()
  const comparator = useMemo(() => getTokenComparator(balances ?? {}), [balances])
  return useMemo(() => {
    if (inverted) {
      return (tokenA: Token, tokenB: Token) => comparator(tokenA, tokenB) * -1
    }
    return comparator
  }, [inverted, comparator])
}


const UserTokens = ()=>{
  const { account } = useWeb3React()
  // const tokenComparator = useTokenComparator(false)

  const allTokens = useAllTokens()
  const filteredTokens: Token[] = useMemo(() => {
    return Object.values(allTokens)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTokens])

  const tokenComparator = useTokenComparator(false)

  const filteredSortedTokens: any[] = useMemo(() => {
    const sorted = filteredTokens.sort(tokenComparator)
    // const sorted = filteredTokens.sort(tokenComparator)
    const ETH = [Currency.ETHER]
    return [
      ...ETH,
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase())
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTokens, tokenComparator])

  return (
    <Wrapper>
      <CoinListContainer>
        {
          (filteredSortedTokens && account && filteredSortedTokens.length > 0)
            ? (
              <CurrencyList
                padding={0}
                height={120}
                showETH={false}
                currencies={filteredSortedTokens}
                onCurrencySelect={()=>null}
                otherCurrency={null}
                selectedCurrency={null}
                fixedListRef={undefined}
                itemSize={40}
                type='user'
              />
            )
            : (
              <div style={{minHeight: '78px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>You do not have any tokens</div>
            )
        }
      </CoinListContainer>

    </Wrapper>
  )
}

export default UserTokens
