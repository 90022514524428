import React from 'react'
import styled from 'styled-components'
import { HelpIcon, Text, Skeleton, useTooltip } from '@hurricaneswap/uikit'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'

const ReferenceElement = styled.div`
  display: inline-block;
  margin-left:5px
`

export interface LiquidityProps {
  liquidity: BigNumber
}

const LiquidityWrapper = styled.div`
  // width: 100%;
  font-weight: 600;
  text-align: right;
  margin-right: 10px;

  ${({ theme }) => theme.mediaQueries.lg} {
    text-align: left;
    margin-right: 3px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 110px;

  @media (max-width: 970px) {
    justify-content: flex-end;
  }
`

const Liquidity: React.FunctionComponent<LiquidityProps> = ({ liquidity }) => {
  const displayLiquidity = liquidity  ? (
    `$${(Number(liquidity) || 0 ).toLocaleString(undefined, { maximumFractionDigits: 0 })}`
  ) : (
    <Skeleton width={60} />
  )
  const { t } = useTranslation()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('The total liquidity locked in a certain liquidity pool in Farm.'),
    { placement: 'top-end', tooltipOffset: [20, 10] },
  )

  return (
    <Container>
      <LiquidityWrapper>
        <Text>{displayLiquidity}</Text>
      </LiquidityWrapper>
      <ReferenceElement ref={targetRef}>
        <HelpIcon color="textSubtle" />
      </ReferenceElement>
      {tooltipVisible && tooltip}
    </Container>
  )
}

export default Liquidity
