import { Token } from '@glhf-libs/sdk'
import { useMemo } from 'react'
import AVAX_TOKENS from '../config/tokens/avax.json'

const EMPTY_LIST = {
  43113: {},
  43114: {}
}

export class WrappedTokenInfo extends Token {
  public readonly tokenInfo

  public readonly tags

  constructor(tokenInfo, tags) {
    super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name)
    this.tokenInfo = tokenInfo
    this.tags = tags
  }

  public get logoURI(): string | undefined {
    return this.tokenInfo.logoURI
  }
}


export function listToTokenMap(list) {
  return list.tokens.reduce(
    (tokenMap, tokenInfo) => {
      const tags =
        tokenInfo.tags
          ?.map(tagId => {
            if (!list.tags?.[tagId]) return undefined
            return { ...list.tags[tagId], id: tagId }
          })
          ?.filter((x) => Boolean(x)) ?? []
      const token = new WrappedTokenInfo(tokenInfo, tags)
      // if (tokenMap[token.chainId][token.address] !== undefined) throw Error('Duplicate tokens.')
      return {
        ...tokenMap,
        [token.chainId]: {
          ...tokenMap[token.chainId],
          [token.address]: token
        }
      }
    },
    { ...EMPTY_LIST }
  )
}

export function useTokenList() {
  return useMemo(() => {
    const current = AVAX_TOKENS
    try {
      return listToTokenMap(current)
    } catch (error) {
      console.error('Could not show token list due to error', error)
      return EMPTY_LIST
    }
  }, [])
}



export function useAllTokens(): { [address: string]: Token } {
  const allTokens = useTokenList()

  return useMemo(() => {
    return (
      []
      // reduce into all ALL_TOKENS filtered by the current chain
        .reduce<{ [address: string]: Token }>(
          (tokenMap, token) => {
            /* eslint no-param-reassign: "error" */
            tokenMap[token.address] = token
            return tokenMap
          },
          // must make a copy because reduce modifies the map, and we do not
          // want to make a copy in every iteration
          { ...allTokens[43114] }
        )
    )
  }, [allTokens])
}

export function useBaseCoin (currency) {
  return useMemo (() => {
    return (
      currency?.symbol === 'Currency' ? 'AVAX' : currency?.symbol
    )
  }, [currency])
}
