import { Currency, currencyEquals, ETHER, Token } from '@glhf-libs/sdk'
import React, { CSSProperties, useCallback, useMemo } from 'react'
import { Box } from 'rebass/styled-components'
import { FixedSizeList } from 'react-window'
import styled, { keyframes, css } from 'styled-components'
import { Text, Flex } from '@hurricaneswap/uikit'
import { useBaseCoin} from "../../hooks/useAllTokens";
import { useAllTokenBalances } from "../../hooks/useBalances";
import AVAX_JSON from  '../../config/tokens/avax.json'

const Row = styled(Box)<{ align?: string; padding?: string; border?: string; borderRadius?: string }>`
  width: 100%;
  display: flex;
  padding: 0;
  align-items: ${({ align }) => (align || 'center')};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius:20px;
`

const RowBetween = styled(Row)`
  justify-content: space-between;
`

function currencyKey(currency: Currency): string {
  if(currency instanceof Token){
    return currency.address
  }
  if(currency === ETHER){
    return 'AVAX'
  }
  return ''
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

const Wrapper = styled.div`
  //padding: 8px 20px;
`

function Balance({ balance }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 16px;
`

const MenuItem = styled(RowBetween)`
  background: rgba(76, 35, 118, 0.04);
  padding:0px 10px;
  border-radius: 0px;
  height: calc( 100% - 2px );
  width: 100%;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.tertiary};
  }
  border-radius: 8px;
 ${({ selected, disabled }) => (selected || disabled) && css`border: 1px solid ${({theme})=>(theme.colors.borderColor)};` }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.colors.primary};
  }
`

const RowFixed = styled(Row)<{ gap?: string; justify?: string }>`
  width: fit-content;
  margin: ${({ gap }) => gap && `-${gap}`};
`

function Loader({ size = '16px', ...rest }) {
  return (
    <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} {...rest}>
      <path
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSVG>
  )
}

const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const tokens = AVAX_JSON.tokens


function CurrencyLogo ({currency, size='24px'}){
  const filteredResult = tokens.filter(i=>i?.symbol.includes(currency?.symbol))

  if(filteredResult && filteredResult.length > 0){
    const src = filteredResult[0].logoURI
    return <StyledBnbLogo src={src} size={size}/>
  }

  return  <StyledBnbLogo src="https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png" size={size}/>
}

function CurrencyRow({
                       balance,
                       currency,
                       onSelect,
                       isSelected,
                       otherSelected,
                       style,
                       type
                     }: {
  balance: any
  currency: Currency
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
  type: any
}) {
  const key = currencyKey(currency)
  const baseCoin = useBaseCoin(currency)


  return (
    <Wrapper
      style={style}
      className={`token-item-${key}`}>
      <MenuItem
        onClick={() => (isSelected ? null : onSelect())}
        disabled={isSelected}
        selected={otherSelected}
      >
       <Flex width='100%' alignItems='center' justifyContent='space-between'>
         <Flex alignItems='center'>
           <CurrencyLogo currency={currency}/>
           <RowWrapper>
             <Text title={currency.name} fontSize={type === 'user' ? '12px' : '16px'}>{ baseCoin }</Text>
           </RowWrapper>
         </Flex>
         <RowFixed style={{ justifySelf: 'flex-end' }}>
           {/* eslint no-param-reassign: "error" */}
           {balance ? <Balance balance={balance} /> : <Loader />}
         </RowFixed>
       </Flex>
      </MenuItem>
    </Wrapper>
  )
}

export default function CurrencyList({
                                       height,
                                       currencies,
                                       selectedCurrency,
                                       onCurrencySelect,
                                       otherCurrency,
                                       fixedListRef,
                                       showETH,
                                       itemSize=90,
                                       padding,
                                       type
                                     }) {
  const Rows = useCallback(
    ({ data, index, style }) => {
      const [c, b] = data
      const currency = c[index]
      const key = currencyKey(currency)
      const balance = b[key]
      const isSelected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, currency))
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))
      const handleSelect = () => onCurrencySelect(currency)
      return (
        <CurrencyRow
          style={{...style, padding, borderRadius: '8px'}}
          currency={currency}
          balance={balance}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
          type={type}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, padding, otherCurrency, selectedCurrency]
  )

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[0][index]), [])

  const balances = useAllTokenBalances()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const itemData = useMemo(()=>([...currencies]), [balances, currencies])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={[itemData, balances]}
      itemCount={itemData.length}
      itemSize={itemSize}
      itemKey={itemKey}
    >
      {Rows}
    </FixedSizeList>
  )
}
