import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount } from '@glhf-libs/sdk'
import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { getAddress } from '@ethersproject/address'
import { useAVAXBalance, useMulticallTokenBalance } from "./useTokenBalance";
import { useAllTokens } from "./useAllTokens";


function isAddress (value: any): string | false {
  try {
    return getAddress (value)
  } catch {
    return false
  }
}

export function useAVAXBalances (
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {

  const addresses: string[] = useMemo (
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
          .map (isAddress)
          .filter ((a): a is string => a !== false)
          .sort ()
        : [],
    [uncheckedAddresses]
  )

  const results = useAVAXBalance()

  return useMemo (
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }> ((memo, address, ) => {
        const value = results.toString()
        /* eslint no-param-reassign: "error" */
        if (value) {
          memo[address] = CurrencyAmount.ether (JSBI.BigInt (value.toString ()))
        }
        return memo
      }, {}),
    [addresses, results]
  )
}


export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {

  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens]
  )

  const balances = useMulticallTokenBalance(validatedTokens)

  return useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
            const value = balances?.[i]?.balance
            const amount = value ? JSBI.BigInt(value.toString()) : undefined
            if (amount) {
              memo[token.address] = new TokenAmount(token, amount)
            }
            return memo
          }, {})
          : {},
      [address, validatedTokens, balances]
    )
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [currencies])
  const tokenBalances = useTokenBalances(account, tokens)
  const containsETH: boolean = useMemo(() => currencies?.some(currency => currency === ETHER) ?? false, [currencies])
  const ethBalance = useAVAXBalances(containsETH ? [account] : [])

  return useMemo(
    () =>
      currencies?.map(currency => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) return tokenBalances[currency.address]
        if (currency === ETHER) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, [...allTokensArray])
  const avaxBalance = useCurrencyBalances(account ?? undefined, [ETHER])

  return useMemo(()=>{
    if(balances && avaxBalance && avaxBalance[0]){
      return {...balances, AVAX: avaxBalance[0]}
    }
    return {}
  }, [avaxBalance, balances])


}

