import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'xHCT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/43114/0x75b797a0ed87f77bb546f3a3556f18fc35a01140/logo.png',
    lpAddresses: {
      43113: '0xe81D3f95C887ce9a89198312774d4274EeC7E43f',
      43114: '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'
    },
    token: tokens.xhct,
    quoteToken: tokens.xhct,
    chain: 'AVAX',
    isPool: true
  },
  {
    pid: 1,
    lpSymbol: 'HCT-WAVAX LP',
    lpAddresses: {
      43114: '0xEa7352aa86C77487F583160098f6982E6f051AF9'
    },
    token: tokens.hct,
    quoteToken: tokens.wavax,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 2,
    lpSymbol: 'HCT-USDT.e LP',
    lpAddresses: {
      43114: '0x22015B828C03ab138cC31e4b78cC7711a7494cb8'
    },
    token: tokens.hct,
    quoteToken: tokens.usdt,
    chain: 'AVAX',
    isPool: false

  },
  {
    pid: 44,
    lpSymbol: 'NHCT-USDT.e LP',
    lpAddresses: {
      43114: '0xA8E2c50E006fAF946e77eEBf8A4CbA8ba99997CF'
    },
    token: tokens.nhct,
    quoteToken: tokens.usdt,
    chain: 'AVAX',
    isPool: false

  },
  {
    pid: 3,
    lpSymbol: 'HCT-USDC.e LP',
    lpAddresses: {
      43114: '0x40f54cAb78B74B4f2B0Fc96D0f91fbd6e85d02a0'
    },
    token: tokens.hct,
    quoteToken: tokens.usdc,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 4,
    lpSymbol: 'HCT-LINK.e LP',
    lpAddresses: {
      43114: '0xcb91E23700ab5C2E6d6DE47dDC05967BA5981768'
    },
    token: tokens.hct,
    quoteToken: tokens.link,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 5,
    lpSymbol: 'HCT-AAVE.e LP',
    lpAddresses: {
      43114: '0x254fC63D38EC5c79f6EdffdB1915Ef8f7F6FECD7'
    },
    token: tokens.hct,
    quoteToken: tokens.aave,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 6,
    lpSymbol: 'HCT-WBTC.e LP',
    lpAddresses: {
      43114: '0xCbDf45926EC40614544626Bed400763e18138188'
    },
    token: tokens.hct,
    quoteToken: tokens.wbtc,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 7,
    lpSymbol: 'HCT-XAVA LP',
    lpAddresses: {
      43114: '0x9E2dDbc27cC7Eb70B19065356647bD2D8C1092ad'
    },
    token: tokens.hct,
    quoteToken: tokens.xava,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 8,
    lpSymbol: 'HCT-QI LP',
    lpAddresses: {
      43114: '0x8686149B566BBF8c0f80D9140e2b4B10EF833abc'
    },
    token: tokens.hct,
    quoteToken: tokens.qi,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 9,
    lpSymbol: 'HCT-VSO LP',
    lpAddresses: {
      43114: '0x2Fa4b528996108b55fe0Ec89a719F6E223698bd7'
    },
    token: tokens.hct,
    quoteToken: tokens.vso,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 10,
    lpSymbol: 'HCT-LYD LP',
    lpAddresses: {
      43114: '0x5B3eA7A290c68E857fff8677b8C9Eb1FD92c7Da1'
    },
    token: tokens.hct,
    quoteToken: tokens.lyd,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 11,
    lpSymbol: 'HCT-O3 LP',
    lpAddresses: {
      43114: '0x6d98DF97567f461475aDF9860D2918f0aa42b56F'
    },
    token: tokens.hct,
    quoteToken: tokens.o3,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 12,
    lpSymbol: 'HCT-aCAKE LP',
    lpAddresses: {
      43114: '0xb2cb42D5610A0bFD39547ea8F2777C9b2FFc511A'
    },
    token: tokens.hct,
    quoteToken: tokens.acake,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 13,
    lpSymbol: 'WAVAX-USDC.e LP',
    lpAddresses: {
      43114: '0x708Ee807cD336190b516BcF099D4340F8F76A5a5'
    },
    token: tokens.wavax,
    quoteToken: tokens.usdc,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 14,
    lpSymbol: 'WAVAX-USDT.e LP',
    lpAddresses: {
      43114: '0x646cD7AE7Ec5e7CD36c46Ea908e0830A46dECf41'
    },
    token: tokens.wavax,
    quoteToken: tokens.usdt,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 15,
    lpSymbol: 'WAVAX-aUSDT LP',
    lpAddresses: {
      43114: '0x8Cf3263BbABf5FE34f6AEe0F81d3eCd4D996c519'
    },
    token: tokens.wavax,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 16,
    lpSymbol: 'WAVAX-WETH.e LP',
    lpAddresses: {
      43114: '0x30948bbB095bfeE420fFc72e15b524D7B225A641'
    },
    token: tokens.wavax,
    quoteToken: tokens.weth,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 17,
    lpSymbol: 'WAVAX-WBTC.e LP',
    lpAddresses: {
      43114: '0xc9abd7CCECd4dbfa1663BfE95e0f6800F7e6aA7C'
    },
    token: tokens.wavax,
    quoteToken: tokens.wbtc,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 18,
    lpSymbol: 'WAVAX-SNOB LP',
    lpAddresses: {
      43114: '0x24bda006d40008fa6a353aedd91a73b1efbd4319'
    },
    token: tokens.wavax,
    quoteToken: tokens.snob,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 19,
    lpSymbol: 'WAVAX-PNG LP',
    lpAddresses: {
      43114: '0xfFbdE223ba865d1aD0f3275A1935e808cE0B229d'
    },
    token: tokens.wavax,
    quoteToken: tokens.png,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 20,
    lpSymbol: 'USDT.e-USDC.e LP',
    lpAddresses: {
      43114: '0xDddad8B5Da8a0DC02B68a00bA3951b20E3c93006'
    },
    token: tokens.usdt,
    quoteToken: tokens.usdc,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 21,
    lpSymbol: 'USDT.e-WBTC.e LP',
    lpAddresses: {
      43114: '0xA141CBa41A28a21aC14f6e15f1DDA60CE7E29663'
    },
    token: tokens.usdt,
    quoteToken: tokens.wbtc,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 22,
    lpSymbol: 'USDT.e-LINK.e LP',
    lpAddresses: {
      43114: '0xed78d4e0276F935605df7F62be720fC782b54822'
    },
    token: tokens.usdt,
    quoteToken: tokens.link,
    chain: 'AVAX',
    isPool: false
  },
  {
    pid: 23,
    lpSymbol: 'WAVAX',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/43114/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7/logo.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
    },
    token: tokens.wavax,
    quoteToken: tokens.wavax,
    chain: 'AVAX',
    isPool: true
  },
  {
    pid: 24,
    lpSymbol: 'sHCT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/43114/0xe4ae2e8648b8e84c4a487a559b04e884b822a350/logo.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0xe4ae2e8648b8e84c4a487a559b04e884b822a350'
    },
    token: tokens.shct,
    quoteToken: tokens.shct,
    chain: 'AVAX',
    isPool: true
  },
  {
    pid: 25,
    lpSymbol: 'bHc-CAKE/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/cake-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0xa5c7bf4d872f24f89120afeac969349a886c726f'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0x34f2284B2Da33c0dB1dED9DFe5A900f4A86C22B1'
    },
    token: tokens.acake,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  },
  {
    pid: 26,
    lpSymbol: 'bHc-XVS/USDT',
    // pool池子要单独写一个logo
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/xvs-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x03706cc5777082edf48c59f2f02519e1fc0af273'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0xf28783841d29c68fa426742c350eed62be636956'
    },
    token: tokens.axvs,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  },
  {
    pid: 27,
    lpSymbol: 'HCT-aXVS',
    // pool池子要单独写一个logo
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x42175cd431cde1774259eef319d1e5f68b7099b3'
    },
    token: tokens.hct,
    quoteToken: tokens.axvs,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 28,
    lpSymbol: 'bHc-MBOX/USDT',
    // pool池子要单独写一个logo
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/mbox-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x633883ac68412f9456a8f017d05e9030ddba96e4'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0xF05810D777895Ec81cDB3ADf3afC0361D3960fa1'
    },
    token: tokens.ambox,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  },
   {
    pid: 29,
    lpSymbol: 'bHc-ALPACA/USDT',
    // pool池子要单独写一个logo
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/alpaca-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x794420f75ddb2c780659b575724d47f90b788687'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0x361d6516020eC71B4A3fCb5733eC923F83D2Db02'
    },
    token: tokens.aalpaca,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  }, 
  {
    pid: 31,
    lpSymbol: 'HCT-aMBOX',
    // pool池子要单独写一个logo
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x3d74288c960b22a9c77027453da9afd9093cf09e'
    },
    token: tokens.hct,
    quoteToken: tokens.ambox,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 30,
    lpSymbol: 'HCT-aALPACA',
    // pool池子要单独写一个logo
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0xfcea6a583c6dc7f388456d83972865676f0f967b'
    },
    token: tokens.hct,
    quoteToken: tokens.aalpaca,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 35,
    lpSymbol: 'HCT-aADA',
    // pool池子要单独写一个logo
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x6895260d94cf8964d5ca9f541fdb150f5d03a918'
    },
    token: tokens.hct,
    quoteToken: tokens.aada,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 32,
    lpSymbol: 'bHc-ADA/USDT',
    // pool池子要单独写一个logo
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/ada-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x662e5f532956880f9fccd5ab39f879e9c0f4a203'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0xd08d6e8ee35838027936a374718665d746b6d0f3'
    },
    token: tokens.aada,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  }, 
  {
    pid: 36,
    lpSymbol: 'HCT-aDOT',
    // pool池子要单独写一个logo
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0xBddFFc95fDC446c10d26519611372A46490218A3'
    },
    token: tokens.hct,
    quoteToken: tokens.adot,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 37,
    lpSymbol: 'bHc-DOT/USDT',
    // pool池子要单独写一个logo
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/dot-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0xff76e179ed4ab2cfa4bc9a17c3201921a575064e'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0xa4a3573a409b70ad57db3452e567de3d9b812a1e'
    },
    token: tokens.adot,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  },  
  {
    pid: 38,
    lpSymbol: 'HCT-aONE',
    // pool池子要单独写一个logo
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x8d809027e4b4ADB4537848e485ff48938Ba22Aa2'
    },
    token: tokens.hct,
    quoteToken: tokens.aone,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 39,
    lpSymbol: 'bHc-ONE/USDT',
    // pool池子要单独写一个logo
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/one-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x929572a42caea7992ebc444e30172f6c3f1be241'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0x6928603640e04ff6d207b8d080b971cd8c7a2705'
    },
    token: tokens.aone,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  },  
  {
    pid: 42,
    lpSymbol: 'HCT-aMATIC',
    // pool池子要单独写一个logo
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x18e6FfbE92fF8F3A985d2533B6c284193B7c7839'
    },
    token: tokens.hct,
    quoteToken: tokens.amatic,
    chain: 'BSC',
    isPool: false
  },
  {
    pid: 43,
    lpSymbol: 'bHc-MATIC/USDT',
    // pool池子要单独写一个logo
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/matic-usdt.png',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x6ed441435e87c4b83ca2e99ff0f4e49f40983748'
    },
    lpAddressesTo: {
      // 如果是bsc跨过来的就写 ausdt和acake 这种avax 上的假的lp
      43114: '0x809C75f207Ae11e40111d2f5664e88f3b68Ab7d0'
    },
    token: tokens.amatic,
    quoteToken: tokens.ausdt,
    chain: 'BSC',
    isPool: true
  },  
// 已有44个
]


export default farms
