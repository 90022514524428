import { useEffect, useState, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useRequest } from "ahooks"
import { useWeb3React } from '@web3-react/core'
import { getBep20Contract, getCakeContract, getContract } from "utils/contractHelpers";
import { BIG_ZERO } from 'utils/bigNumber'
import bep20Abi from 'config/abi/erc20.json'
import useWeb3 from './useWeb3'
import useRefresh from './useRefresh'
import useLastUpdated from './useLastUpdated'
import multicallAbi from '../config/abi/Multicall.json'
import multicall from "../utils/multicall";



const useTokenBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account } = useWeb3React()
  const web3 = useWeb3()

  useMemo(() => {
    const fetchBalance = async () => {
      const contract = getBep20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf(account).call()
      setBalance(new BigNumber(res))
    }

    if (account && tokenAddress) {
      fetchBalance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenAddress, web3])

  return balance
}

export const useMulticallTokenBalance = (tokenAddresses)=>{
  const [balance, setBalance] = useState([])
  const { account } = useWeb3React()
  const param = tokenAddresses.map(i=>{
    if(i?.address){
      return ({
        address: i?.address,
        name: 'balanceOf',
        params: [account],
      })
    }
    return null
  })

  const {run, data, loading} = useRequest(()=>multicall(bep20Abi, param))

  useMemo(() => {
    if(account && data){
      setBalance(data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, data])

  return balance
}

export const useAVAXBalance = () => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account } = useWeb3React()
  const web3 = useWeb3()
  const contract = getContract(multicallAbi, '0xeC03fE71EcE55684a3cbfCF74a102a94d005A110', web3)
  const {run, data, loading} = useRequest(()=>contract.methods.getEthBalance(account).call())

  useMemo(() => {
    if(account && data){
      setBalance(new BigNumber(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, data])

  return balance
}

export const useTotalSupply = () => {
  const { slowRefresh } = useRefresh()
  const [totalSupply, setTotalSupply] = useState<BigNumber>()

  useEffect(() => {
    async function fetchTotalSupply() {
      const cakeContract = getCakeContract()
      const supply = await cakeContract.methods.totalSupply().call()
      setTotalSupply(new BigNumber(supply))
    }

    fetchTotalSupply()
  }, [slowRefresh])

  return totalSupply
}

export const useBurnedBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { slowRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getBep20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call()
      setBalance(new BigNumber(res))
    }

    fetchBalance()
  }, [web3, tokenAddress, slowRefresh])

  return balance
}

export const useGetBnbBalance = () => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account } = useWeb3React()
  const { lastUpdated, setLastUpdated } = useLastUpdated()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchBalance = async () => {
      const walletBalance = await web3.eth.getBalance(account)
      setBalance(new BigNumber(walletBalance))
    }

    if (account) {
      fetchBalance()
    }
  }, [account, web3, lastUpdated, setBalance])

  return { balance, refresh: setLastUpdated }
}

export default useTokenBalance
