import React, { lazy, useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ConnectorNames, ResetCSS } from '@hurricaneswap/uikit'
import useAuth from 'hooks/useAuth'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPriceList, useFetchProfile, useFetchPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
// import Pools from './views/Pools'
import history from './routerHistory'
import Farms,{Pool} from './views/Farms'
import Footer from "./components/PageFooter"



// const Home = lazy(() => import('./views/Home'))
const NotFound = lazy(() => import('./views/NotFound'))


BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const BackgroundCover = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all linear .2s;
    background: ${({theme})=>(theme.colors.gradients.backgroundCover)};
    z-index:-2;
    
`

const App: React.FC = () => {
  useEagerConnect()
  useFetchPublicData()
  useFetchProfile()
  useFetchPriceList()

  const {account} = useWeb3React()
  const {login} = useAuth()
  useEffect(()=>{
    if(!account){
      login(ConnectorNames.Injected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router history={history} >
      <ResetCSS />
      <GlobalStyle />

      <BackgroundCover/>

      <section id='menu'>
        <Menu>
          <SuspenseWithChunkError fallback={<PageLoader />}>
            <Switch>
              {/* </Route> */}
              <Route exact path="/">
                  <Farms/>
              </Route>
              <Route exact path="/history">
                  <Farms/>
              </Route>
              <Route exact path="/pool">
                  <Pool/>
              </Route>

               <Redirect to="/" />
              {/*  404  */}
               <Route component={NotFound} />
            </Switch>
          </SuspenseWithChunkError>
        </Menu>
        
      </section>
      <Footer/>
      




      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
