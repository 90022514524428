import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { ArrowDropDownIcon, Text, Flex, ChevronRightIcon, ChevronDownIcon } from '@hurricaneswap/uikit'
import useTheme from "../../hooks/useTheme";
import useMobile from "../../hooks/useMobile";

const DropDownHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  border: 1px solid ${({theme})=>(theme.isDark ? '#5723A6' : 'rgba(255, 255, 255, 0.6)')};
  border-radius: 8px;
  // background: #FFFFFF;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,0.6)'};


  transition: border-radius 0.15s;
`

const DropDownListContainer = styled.div<{ifMobile:boolean}>`
  min-width: 136px;
  height: 0;
  position: absolute;
  overflow: hidden;
  // background: #FFFFFF;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;


  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: ${({ ifMobile }) => ifMobile ? '46vw' : '200px'} ;
  }
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number , ifMobile:boolean}>`
  cursor: pointer;
  width: ${({ width , ifMobile}) => ifMobile ? '46vw' : `${Math.min(width,200)}px`};
  position: relative;
  // background: #FFFFFF;
  // background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  border-radius: 8px;
  height: 40px;
  min-width: 136px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 168px;
  }
  ${DropDownHeader}{
    background: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255,255,255,0.6)'};
    border: 1px solid ${({theme})=>(theme.isDark ? '#8D80D5' : 'rgba(255, 255, 255, 0.6)')};  
  }
  ${(props) =>
    props.isOpen &&
    css`
      
      ${DropDownHeader}{
        background: ${({ theme }) => theme.isDark ? '#1D142B' : '#FFFFFF'};
        border: 1px solid #5723A6;
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border: 0px solid ${({ theme }) => theme.colors.textSubtle};
        border-top-width: 0;
        border-radius: 8px;
        box-shadow: 0px 0px 8px rgba(100, 37, 197, 0.25);
        margin-top:2px;
          // border: 1px solid #5723A6;
      }
    `
  }

  .arrow {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const SubTab =  styled.div<{ secIsOpen: boolean, ifMobile:boolean }>`
  
  border: 0px solid ${({ theme }) => theme.colors.textSubtle};
  border-left: 1px solid ${({theme}) => theme.isDark ? '#4D3C55':'#E5E3E9'};
  display: ${({secIsOpen} )=> secIsOpen ? 'show':'none' };
  border-top-width: 0;
  border-radius: 0px 8px 8px 0px;
  // position: absolute;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : '#FFFFFF'};
  // right:0;
  // top:41px;
  width: ${({ ifMobile }) => ifMobile ? '46vw' : '200px'} ;
  div:nth-child(1){
    border-top-right-radius:8px
  }
  div:nth-last-child(1){
    border-bottom-right-radius:8px
  }
  div {
    padding: 8px 16px;
    height:40px;
    font-family: GTText;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
   // color: #24125B;
    &:hover {
      background:rgba(141, 128, 213, 0.1);
    }
    cursor:pointer
  }
` 

const DropDownList = styled.ul<{ifMobile:boolean }>`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // width:calc( 100% - 200px);
  width: ${({ ifMobile }) => ifMobile ? '46vw' : '200px'} ;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  li:nth-child(1){
    border-top-left-radius:8px
  }
  // li:nth-child(1){
  //  border-top-left-radius:8px
  // }
  // i:nth-last-child(1){
  //  border-bottom-left-radius:8px
  // }
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;
  &:hover {
   // background: ${({theme})=>(theme.isDark ? 'rgba(255,255,255,0.2)' : '#F4F4F6')};
    background:rgba(141, 128, 213, 0.1);
  }
`

export interface SelectProps {
  options: OptionProps[]
  onChange?: (first:OptionProps, option: SubProps) => void
}

interface SubProps {
  label: string
  value: any
}

export interface OptionProps {
  label: string
  value:any
  children: SubProps[]
}


const SubSelect: React.FunctionComponent<SelectProps> = ({ options, onChange }) => {
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [secIsOpen, setSecIsOpen] = useState(false)
  const ifMobile = useMobile()

  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedSub, setSelectedSub] = useState(null)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const {isDark} =useTheme()

  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(!isOpen); 
    setSecIsOpen(false);
    event.stopPropagation()
  }

  const onOptionClicked = (option) => (event) => {
    setSelectedOption(option)
    // setIsOpen(false)
    setSecIsOpen(true)
    setSelectedSub(null)
    event.stopPropagation()

  }
  const onSubClicked = (option) => () => {
    setSelectedSub(option)
    setIsOpen(false)
    setSecIsOpen(false)
    if (onChange) {
      onChange(selectedOption,option)
    }
  }

  useEffect(() => {
    setContainerSize({
      width: dropdownRef.current.offsetWidth, // Consider border
      height: dropdownRef.current.offsetHeight,
    })
  }, [])

  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize} ifMobile={ifMobile}>
      {containerSize.width !== 0 && (
        <DropDownHeader onClick={toggling} className={ isOpen ? 'select' : ''}>
          <Text color={isDark ? 'rgba(255,255,255,0.25)' : 'rgba(77,60,85,0.5)'}>{ (selectedOption?.label && selectedSub?.label) ? `${selectedOption?.label}/${selectedSub?.label}` : 'Filter by'}</Text>
        </DropDownHeader>
      )}
      <ChevronDownIcon color="text" onClick={toggling} className='arrow'/>
      <DropDownListContainer ifMobile={ifMobile}>
        <Flex flexDirection='row' alignItems='space-around'>
          <DropDownList ref={dropdownRef} ifMobile={ifMobile}>
            {options.map((option) =>
              (
                <ListItem onClick={onOptionClicked(option)} key={option.label}>
                  <Flex flexDirection='row' width='100%' justifyContent='space-between' >
                    <Text>
                      {option.label}
                    </Text>
                    <ChevronRightIcon color={ secIsOpen ? 'textDisabled' : 'primary'} />
                  </Flex>
                </ListItem>
              ) 
            )}
          </DropDownList>
          <SubTab secIsOpen={secIsOpen} ifMobile={ifMobile}>
          {
            selectedOption?.children.map((i) => (
              <Text onClick={onSubClicked(i)} key={i.label}>
                {i.label}
              </Text>
                
            ))
            }
          </SubTab>
        </Flex>

      </DropDownListContainer>

    </DropDownContainer>
  )
}


export default SubSelect


/* {option.children.map((i) => (
  <div>{i.label}</div>
))} */