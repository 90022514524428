import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { ArrowDropDownIcon, Text, ChevronDownIcon} from '@hurricaneswap/uikit'
import useTheme from "../../hooks/useTheme";
import useMobile from "../../hooks/useMobile";

const DropDownHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  border: 1px solid ${({theme})=>(theme.isDark ? '#5723A6' : 'rgba(255, 255, 255, 0.6)')};
  border-radius: 8px;
  // background: #FFFFFF;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,0.6)'};


  transition: border-radius 0.15s;
`

const DropDownListContainer = styled.div<{ifMobile:boolean }>`
  // min-width: 168px;
  width: ${({ ifMobile }) => ifMobile ? '46vw' : '168px'} ;
  height: 0;
  position: absolute;
  overflow: hidden;
  // background: #FFFFFF;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;


  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 168px;
  }
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number, ifMobile:boolean }>`
  cursor: pointer;
  // width: ${({ width }) => width}px;
  width: ${({ ifMobile }) => ifMobile ? '46vw' : '168px'} ;
  position: relative;
  // background: #FFFFFF;
  // background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  border-radius: 8px;
  height: 40px;
  min-width: 136px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 148px;
  }
  ${DropDownHeader}{
    background: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255,255,255,0.6)'};
    border: 1px solid ${({theme})=>(theme.isDark ? '#8D80D5' : 'rgba(255, 255, 255, 0.6)')};  
  }
  ${(props) =>
    props.isOpen &&
    css`
      
      ${DropDownHeader}{
        background: ${({ theme }) => theme.isDark ? '#1D142B' : '#FFFFFF'};
        border: 1px solid #5723A6;
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border: 0px solid ${({ theme }) => theme.colors.textSubtle};
        border-top-width: 0;
        border-radius: 8px;
        box-shadow: 0 20px 20px rgba(159, 56, 140, 0.12);
        margin-top:1px;
          border: 1px solid #5723A6;
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;
  &:hover {
    background: ${({theme})=>(theme.isDark ? 'rgba(255,255,255,0.2)' : '#F4F4F6')};
  }
`

export interface SelectProps {
  options: OptionProps[]
  onChange?: (option: OptionProps) => void
}

export interface OptionProps {
  label: string
  value: any
}

const Select: React.FunctionComponent<SelectProps> = ({ options, onChange }) => {
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const {isDark} =useTheme()
  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(!isOpen)
    event.stopPropagation()
  }
  const ifMobile = useMobile()

  const onOptionClicked = (option: OptionProps) => () => {
    setSelectedOption(option)
    setIsOpen(false)

    if (onChange) {
      onChange(option)
    }
  }

  useEffect(() => {
    setContainerSize({
      width: dropdownRef.current.offsetWidth, // Consider border
      height: dropdownRef.current.offsetHeight,
    })
  }, [])
  
  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize} ifMobile={ifMobile}>
      {containerSize.width !== 0 && (
        <DropDownHeader onClick={toggling} className={ isOpen ? 'select' : ''}>
          <Text color={isDark ? 'rgba(255,255,255,0.25)' : 'rgba(77,60,85,0.5)'}>{selectedOption.label}</Text>
        </DropDownHeader>
      )}
      <ChevronDownIcon color="text" onClick={toggling} />
      <DropDownListContainer ifMobile={ifMobile}>
        <DropDownList ref={dropdownRef}>
          {options.map((option) =>
            option.label !== selectedOption.label ? (
              <ListItem onClick={onOptionClicked(option)} key={option.label}>
                <Text>{option.label}</Text>
              </ListItem>
            ) : null,
          )}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  )
}

export default Select
