import React, { useContext } from 'react'
import styled from 'styled-components'
import { Flex, TwitterIcon, TelegramIcon, MediumIcon } from '@hurricaneswap/uikit'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import useMobile from '../../hooks/useMobile'
import ImmunefiIcon from '../../assets/images/immunefi.svg'
import DiscordIcon from '../../assets/images/discord.svg'
import GithubIcon from '../../assets/images/github.svg'

const Socialflex = styled(Flex)`
  justify-content:left;
  margin-top:10px;
 svg {
   margin-right:20px
 }
 `

/* const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid rgba(77, 60, 85, 0.12);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  //margin-top: 113px;
` */

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 220px;
  // border-top: 1px solid rgba(77, 60, 85, 0.12);
  display: flex;
  height:70px;
  justify-content: center;
  flex-direction: column;
  //margin-top: 113px;
  background: ${({ theme }) => (theme.isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.4)')};
`
const MinWrapper = styled.div`
  width:100%;
  padding-bottom:70px;
  text-align:center;
  color: ${({ theme }) => (theme.isDark ? 'rgba(255, 255, 255, 0.6)' : '#C7C8D9')};
`

const Block = styled.div`
  margin-top:10px;  
  a{
    margin:0 10px;
    vertical-align: middle;
  }
`
const Text = styled(Link)`
  margin-right: 56px;
  color: ${({ theme }) => (theme.isDark ? 'rgba(255, 255, 255, 0.6)' : '#4D3C55')};
  &:hover{
    color: ${({ theme }) => theme.colors.primary};
  };
  font-family: GTText;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
    
  @media (max-width: 510px) {
    margin-right: 24px;
  }
`

const TextLink = styled.a`
  color: ${({ theme }) => (theme.isDark ? 'rgba(255, 255, 255, 0.6)' : '#4D3C55')};
  &:hover{
    color: ${({ theme }) => theme.colors.primary};
  };
  font-family: GTText;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
    
  @media (max-width: 510px) {
    margin-right: 24px;
  }
`

const Privacy = styled.div`
font-family: GTText;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: ${({ theme }) => (theme.isDark ? 'rgba(255, 255, 255, 0.6)' : '#4D3C55')};
`



const getLanguageColor = ({ active, theme }) => {
  let color
  if (active && theme.isDark) {
    color = 'rgba(255,255,255,0.85)'
  }
  if (!active && theme.isDark) {
    color = 'rgba(255,255,255,0.45)'
  }
  if (active && !theme.isDark) {
    color = 'rgba(77, 60, 85, 0.85)'
  }
  if (!active && !theme.isDark) {
    color = 'rgba(77, 60, 85, 0.45)'
  }
  return color
}
const Language = styled.div<{ active: boolean | undefined, theme }>`
cursor: pointer;
font-family: GTText;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
color: ${getLanguageColor};
`

const Footer = () => {
  const { t } = useTranslation()
  const ifMobile = useMobile()
  return (
    <>
      {
        ifMobile ?
          <MinWrapper>
            <Block>
              <a href="https://twitter.com/hurricaneswap" aria-label="https://twitter.com/hurricaneswap"><TwitterIcon /></a>
              <a href="https://t.me/HurricaneSwap" aria-label="https://t.me/HurricaneSwap"><TelegramIcon /></a>
              <a href="https://hurricaneswap.medium.com" aria-label="https://hurricaneswap.medium.com"><MediumIcon /></a>
            </Block>
            <Block>bugbounty@hurricaneswap.com</Block>
            <Block>
              © 2021 HurricaneSwap
            </Block>
          </MinWrapper>
          :
          <Wrapper>
            <Flex justifyContent='space-around'>
              <Privacy>
                © 2018-2021 Hurricaneswap. All rights reserved.
              </Privacy>
              <Flex justifyContent='space-between' alignItems='center' width='300px'>

                <TextLink href="https://hurricaneswap.gitbook.io/hurricaneswap/faq/1.-what-is-hurricaneswap">
                  {t('Help')}
                </TextLink>
                <TextLink href="https://hurricaneswap.medium.com/">
                  {t('Blog')}
                </TextLink>
                <a href="https://github.com/HurricaneSwap" rel="noreferrer" target="_blank" aria-label='social'><img width='20px' height='20px' alt='git' src={GithubIcon} /></a>
                <a href="https://twitter.com/hurricaneswap" aria-label='social'><TwitterIcon /></a>
                <a href="https://t.me/HurricaneSwap" aria-label='social'><TelegramIcon /></a>
                <a href="https://hurricaneswap.medium.com" aria-label='social'><MediumIcon /></a>
                <a href="https://discord.gg/s2Y7wanVyQ" rel="noreferrer" target="_blank" aria-label='social'><img width='20px' height='20px' alt='Hurricane discord' src={DiscordIcon} /></a>
                <a href="https://immunefi.com/bounty/hurricaneswap/" rel="noreferrer" target="_blank" aria-label='social'><img width='20px' height='20px' alt='git' src={ImmunefiIcon} /></a>
              </Flex>
            </Flex>
          </Wrapper>
      }

    </>
  )
}

export default Footer
