import React, { useMemo } from "react";
import styled from 'styled-components'
import { ListViewIcon, CardViewIcon, IconButton } from '@hurricaneswap/uikit'
import { ViewMode } from '../types'
import useTheme from "../../../../hooks/useTheme";

interface ToggleViewProps {
  viewMode: ViewMode
  onToggle: (mode: ViewMode) => void
}

const Container = styled.div`
  margin-left: -8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 0;
  }
`

const IconContainer = styled(IconButton)`
  width: 32px;
  height: 32px;
  background: ${({theme})=>(theme.isDark ? ' rgba(255, 255, 255, 0.45)' : ' rgba(255, 255, 255, 0.6)' )};
  border-radius: 8px;
`

const ToggleView: React.FunctionComponent<ToggleViewProps> = ({ viewMode, onToggle }) => {
  const handleToggle = (mode: ViewMode) => {
    if (viewMode !== mode) {
      onToggle(mode)
    }
  }

  const {isDark} = useTheme()
  const cardActiveColor = useMemo(()=>{
    if(viewMode === ViewMode.CARD){
      return  (isDark ? 'rgba(255,255,255,0.45)' : 'rgba(77, 60, 85, 0.12)')
    }
      return  (isDark ? '#FFF' : '#676490')
  }, [viewMode, isDark])

  const tableActiveColor = useMemo(()=>{
    if(viewMode === ViewMode.TABLE){
      return  (isDark ? 'rgba(255,255,255,0.45)' : 'rgba(77, 60, 85, 0.12)')
    }
    return  (isDark ? '#FFF' : '#676490')
  }, [viewMode, isDark])

  return (
    <Container>
      <IconContainer mr='16px' variant="text" scale="sm" onClick={() => handleToggle(ViewMode.TABLE)}>
        <ListViewIcon color={cardActiveColor} />
      </IconContainer>
      <IconContainer variant="text" scale="sm" onClick={() => handleToggle(ViewMode.CARD)}>
        <CardViewIcon color={tableActiveColor} />
      </IconContainer>
    </Container>
  )
}

export default ToggleView
