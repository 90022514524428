import React, {useMemo} from 'react'
import { Navbar } from '@hurricaneswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import config from './config'
import useMobile from "../../hooks/useMobile";
import UserTokens from "../UserTokens";



const coinList = ()=>{
  return <UserTokens/>
}


export const getNetwork = (chainId) => {
  let network
  if (chainId === 97) {
    network = 'Test-BSC'
  } else if (chainId === 56) {
    network = 'BSC'
  } else if (chainId === 256) {
    network = 'TEST-HECO'
  } else if (chainId === 128) {
    network = 'HECO'
  } else if (chainId === 43113) {
    network = 'FUJI'
  } else {
    network = 'AVAX'
  }
  return network
}

const VersionIcon = () => {
  return (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4C0 1.79086 1.79086 0 4 0H28V16C28 18.2091 26.2091 20 24 20H0V4Z" fill="url(#paint0_linear_3367_19522)" />
      <path d="M13.432 8.456L10.552 14.12H9.904L7.012 8.456H9.016L9.892 10.532C10.084 10.98 10.192 11.328 10.216 11.576H10.24C10.272 11.288 10.38 10.94 10.564 10.532L11.44 8.456H13.432ZM17.4853 5.72C18.2693 5.72 18.8733 5.908 19.2973 6.284C19.7213 6.652 19.9333 7.18 19.9333 7.868C19.9333 8.292 19.8453 8.696 19.6693 9.08C19.4933 9.464 19.1813 9.94 18.7333 10.508L17.7013 11.816C17.4693 12.104 17.2613 12.324 17.0773 12.476L17.1013 12.512C17.3893 12.416 17.8453 12.368 18.4693 12.368H20.4133L20.4253 12.392L19.4893 14H14.0053L13.9453 13.88L17.3173 9.608C17.8133 8.976 18.0613 8.488 18.0613 8.144C18.0613 7.912 17.9893 7.732 17.8453 7.604C17.7013 7.476 17.5013 7.412 17.2453 7.412C16.7013 7.412 16.0893 7.656 15.4093 8.144L14.5333 6.716C15.5253 6.052 16.5093 5.72 17.4853 5.72Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_3367_19522" x1="-0.407766" y1="7.05882" x2="20.4987" y2="19.5818" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A78EEF" />
          <stop offset="0.945858" stopColor="#5B12CB" />
        </linearGradient>
      </defs>
    </svg>

  )
}


const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { currentLanguage, setLanguage } = useTranslation()
  const { isDark, toggleTheme, theme } = useTheme()
  const {t} = useTranslation()
  const ifMobileForNav = useMobile(1200)
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  const network = getNetwork(chainId)
  // console.log(chainId,'wangluo')

  const lng = useMemo(()=>{
    return currentLanguage?.code.split('-')[0]
  }, [currentLanguage])

  // <Navbar
  // links={links}
  // account={account || undefined}
  // login={login}
  // logout={deactivate}
  // isDark={isDark}
  // toggleTheme={toggleTheme}
  // currentLang={selectedLanguage?.code || ''}
  // langs={allLanguages}
  // setLang={setSelectedLanguage}
  // coinList = {UserTokens}
  // SearchToken={SearchTokens}
  // style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `${theme.colors.gradients.backgroundCover} fixed`, opacity: 0.85}}
  // {...props}
  // />

  return (
      <Navbar
        ifMobile={ifMobileForNav}
        account={account}
        login={login}
        logout={logout}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={lng}
        langs={languageList}
        setLang={setLanguage}
        links={config}
        t={t}
        coinList = {coinList}
        SearchToken={null}
        style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `${theme.colors.gradients.backgroundCover} fixed`, opacity: 0.85}}
        {...props}
        network={network}
        version={VersionIcon}
      />
  )
}

export default Menu
