const config = [
  {
    label: "Home",
    href: "https://hurricaneswap.com/"
  },
  {
    label: "Convert",
    href: "https://hurricaneswap.com/#/convert",
  },
  {
    label: "Swap",
    href: "https://hurricaneswap.com/#/swap",
  },
  {
    label: "Liquidity",
    href: "https://hurricaneswap.com/#/liquidity"
  },
  // {
  //   label: "Stake",
  //   href: "https://hurricaneswap.com/#/stake",
  // },
  {
    label: "Earn",
    href: 'https://farm.hurricaneswap.com/',
    children: [
      {
        label: "Stake",
        href: "https://hurricaneswap.com/#/stake",
      },
      {
        label: "Lock sHCT",
        href: "https://hurricaneswap.com/#/lock"
      },
      {
        label: "Farm & Pool",
        href: "/",
      }
    ]
  },
  {
    label: "Transfer",
    children: [
      {
        label: "Station",
        href: "https://hurricaneswap.com/#/stationpool",
      },
      {
        label: "Bridge",
        href: "https://hurricaneswap.com/#/deposit"
      },
    ]
  },
  {
    label: "More...",
    children: [
      {
        label: "Info",
        href: "https://hurricaneswap.com/#/info/overview"
      },
      {
        label: "Vote",
        href: "https://snapshot.org/#/hurricanedao.eth",
        target: "_blank"
      }
    ]
  }
];




export default config
