const tokens = {
  avax: {
    symbol: 'AVAX',
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/logo.png"
  },
  xhct: {
    symbol: 'xHCT',
    address: {
      43114: '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x75b797a0ed87f77bb546f3a3556f18fc35a01140/logo.png"
  },
  hct: {
    symbol: 'HCT',
    address: {
      43114: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x45c13620b55c35a5f539d26e88247011eb10fdbd/logo.png"
  },
  nhct: {
    symbol: 'NHCT',
    address: {
      43114: '0x3CE2fceC09879af073B53beF5f4D04327a1bC032'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x3ce2fcec09879af073b53bef5f4d04327a1bc032/logo.png"
  },
  wavax: {
    symbol: 'WAVAX',
    address: {
      43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7/logo.png"
  },
  link: {
    symbol: 'LINK.e',
    address: {
      43114: '0x5947BB275c521040051D82396192181b413227A3'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x5947bb275c521040051d82396192181b413227a3/logo.png"
  },
  xava: {
    symbol: 'XAVA',
    address: {
      43114: '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xd1c3f94de7e5b45fa4edbba472491a9f4b166fc4/logo.png"
  },
  usdt: {
    symbol: 'USDT.e',
    address: {
      43114: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'
    },
    decimals: 6,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png",
  },
  usdc: {
    symbol: 'USDC.e',
    address: {
      43114: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664'
    },
    decimals: 6,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664/logo.png",
  },
  wbtc: {
    symbol: 'WBTC.e',
    address: {
      43114: '0x50b7545627a5162F82A992c33b87aDc75187B218'
    },
    decimals: 8,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x50b7545627a5162f82a992c33b87adc75187b218/logo.png",
  },
  weth: {
    symbol: 'WETH.e',
    address: {
      43114: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab/logo.png",
  },
  aave: {
    symbol: 'AAVE.e',
    address: {
      43114: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x63a72806098bd3d9520cc43356dd78afe5d386d9/logo.png"
  },
  qi: {
    symbol: 'QI',
    address: {
      43114: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x8729438eb15e2c8b576fcc6aecda6a148776c0f5/logo.png",
  },
  crv: {
    symbol: 'CRV.e',
    address: {
      43114: '0x249848BeCA43aC405b8102Ec90Dd5F22CA513c06'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x249848beca43ac405b8102ec90dd5f22ca513c06/logo.png",
  },
  snob: {
    symbol: 'SNOB',
    address: {
      43114: '0xc38f41a296a4493ff429f1238e030924a1542e50'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc38f41a296a4493ff429f1238e030924a1542e50/logo.png",
  },
  png: {
    symbol: 'PNG',
    address: {
      43114: '0x60781c2586d68229fde47564546784ab3faca982'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x60781c2586d68229fde47564546784ab3faca982/logo.png",
  },
  vso: {
    symbol: 'VSO',
    address: {
      43114: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a/logo.png",
  },
  o3: {
    symbol: 'O3',
    address: {
      43114: '0xee9801669c6138e84bd50deb500827b776777d28'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xee9801669c6138e84bd50deb500827b776777d28/logo.png",
  },
  lyd: {
    symbol: 'LYD',
    address: {
      43114: '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084/logo.png",
  },
  acake: {
    symbol: 'aCAKE',
    address: {
      43114: '0x7ed05cf8f77fb75a10947e0715c0876e074ff676'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x7ed05cf8f77fb75a10947e0715c0876e074ff676/logo.png",
  },
  ausdt: {
    symbol: 'aUSDT',
    address: {
      43114: '0xfbbc6be26e1712685848b7194365600513cf73ca'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png",
  },
  shct: {
    symbol: 'sHCT',
    address: {
      43114: '0xe4ae2e8648b8e84c4a487a559b04e884b822a350'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xe4ae2e8648b8e84c4a487a559b04e884b822a350/logo.png",

  },
  axvs: {
    symbol: 'aXVS',
    address: {
      43114: '0x447277000223f029ce8752cd2b06255fc1e29107'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x447277000223f029ce8752cd2b06255fc1e29107/logo.png",

  },
  ambox: {
    symbol: 'aMBOX',
    address: {
      43114: '0x0cd70a6aa778c98f305112e9002dd6c3361c5920'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x0cd70a6aa778c98f305112e9002dd6c3361c5920/logo.png",

  },
  aalpaca: {
    symbol: 'aALPACA',
    address: {
      43114: '0x2441421119da997ecaf08337846f8feda0f1ed87'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x2441421119da997ecaf08337846f8feda0f1ed87/logo.png",

  },
  aada: {
    symbol: 'aADA',
    address: {
      43114: '0x6cf0052988a0dc94eda667851cacef70f2812ce6'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x6cf0052988a0dc94eda667851cacef70f2812ce6/logo.png",

  },
  adot: {
    symbol: 'aDOT',
    address: {
      43114: '0x63Bc41817Ef8E111B4a3ED97aBcE64f8F8fc7fef'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x63bc41817ef8e111b4a3ed97abce64f8f8fc7fef/logo.png",

  },
  aone: {
    symbol: 'aONE',
    address: {
      43114: '0xfcbad95e80a5b2df70e319013167d98ea9929745'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xfcbad95e80a5b2df70e319013167d98ea9929745/logo.png",

  },
  amatic: {
    symbol: 'aMATIC',
    address: {
      43114: '0x9C9d7b3D0B21c4c31DBC68477De2Ddf633B71565'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x9c9d7b3d0b21c4c31dbc68477de2ddf633b71565/logo.png",

  },

}

export default tokens
