import React from 'react'
import styled from 'styled-components'
import { HelpIcon, Skeleton, useTooltip } from '@hurricaneswap/uikit'
import { useTranslation } from 'contexts/Localization'

const ReferenceElement = styled.div`
  display: inline-block;
`

export interface ChainProps {
  chain: string
}

const MultiplierWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text};
  // width: 36px;
  text-align: right;
  margin-right: 14px;

  ${({ theme }) => theme.mediaQueries.lg} {
    text-align: left;
    margin-right: 3px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Multiplier: React.FunctionComponent<ChainProps> = ({...props}) => {
  return (
    <Container>
      <MultiplierWrapper>{props.chain}</MultiplierWrapper>
    </Container>
  )
}

export default Multiplier
