import React, { useState, useEffect }  from 'react'
import styled from 'styled-components'
import { useTable, Button, ColumnType, Text, ChevronLeftIcon , ChevronRightIcon } from '@hurricaneswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Row, { RowProps } from './Row'

export interface ITableProps {
  data: RowProps[]
  columns: ColumnType<RowProps>[]
  userDataReady: boolean
  sortColumn?: string
}

const Container = styled.div`
  // filter: ${({ theme }) => theme.card.dropShadow};
  width: 100%;
  background: ${({ theme }) => theme.isDark ? 'rgba(29, 20, 43, 0.4)':'rgba(255, 255, 255, 0.4)'};
  border-radius: 20px;
  margin: 16px 0px 64px 0px;
  box-shadow: 0px 16px 50px rgba(100, 37, 197, 0.06);
`
export const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height:80px;
  margin-top: 0.2em;
  margin-bottom: 1.2em;
`

export const Arrow = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`


const TableWrapper = styled.div`
  overflow: visible;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

const TableBody = styled.tbody`
  & tr {
    td {
      font-size: 16px;
      vertical-align: middle;
    }
  }
`

const TableContainer = styled.div`
  position: relative;
`

const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
`

const FarmTable: React.FC<ITableProps> = (props) => {
  const { t } = useTranslation()
  const { data, columns, userDataReady } = props


  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [pageRow, setPageRow] = useState([])
  const ITEMS_PER_INFO_TABLE_PAGE = 9
  useEffect(() => {
    let extraPages = 1
    if (data.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(data.length / ITEMS_PER_INFO_TABLE_PAGE) + extraPages)
  }, [data.length])

  const { rows } = useTable(columns, data, { sortable: true, sortColumn: 'farm' })

  useEffect(() => {
    if (page > maxPage){
      setPage(maxPage)
    }
    
 }, [page,maxPage])

  useEffect(() => {
     setPageRow(rows.slice((page - 1) * ITEMS_PER_INFO_TABLE_PAGE,page * ITEMS_PER_INFO_TABLE_PAGE))
  }, [page,rows])

  const scrollToTop = (): void => {
    const app = document.getElementById('root')
    if(app){
      app.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }
  return (
    <Container>
      <TableContainer>
        <TableWrapper>
          <StyledTable>
            <TableBody>
              {pageRow.map((row) => {
                return <Row {...row.original} userDataReady={userDataReady} key={`table-row-${row.id}`} />
              })}
            </TableBody>
          </StyledTable>
        </TableWrapper>
       {/* <ScrollButtonContainer>
          <Button variant="text" onClick={scrollToTop}>
            {t('To Top')}
            <ChevronUpIcon color="primary" />
          </Button>
       </ScrollButtonContainer> */}
          <PageButtons>
            <Arrow
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <ChevronLeftIcon color={page === 1 ? 'textDisabled' : 'primary'} />
            </Arrow>

            {/* <Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text> */}
            <Text>Page {page} of {maxPage}</Text>

            <Arrow
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <ChevronRightIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
            </Arrow>
          </PageButtons>
      </TableContainer>
    </Container>
  )
}

export default FarmTable
