import random from 'lodash/random'

// Array of available nodes to connect to


export const nodes =   process.env.REACT_APP_CHAIN_ID === "43113"  ? [process.env.REACT_APP_NODE_1_TEST, process.env.REACT_APP_NODE_2_TEST, process.env.REACT_APP_NODE_3_TEST]: [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3]

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl
