// @ts-nocheck
import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 8,  
    stakingToken: tokens.xhct,
    earningToken: tokens.xhct,
    contractAddress: {
      43114: '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 7,  
    stakingToken: tokens.hct,
    earningToken: tokens.qi,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 0,
    stakingToken: tokens.hct,
    earningToken: tokens.hct,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: tokens.hct,
    earningToken: tokens.wavax,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: tokens.hct,
    earningToken: tokens.link,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 3,
    stakingToken: tokens.hct,
    earningToken: tokens.aave,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 4,
    stakingToken: tokens.hct,
    earningToken: tokens.wbtc,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 5,
    stakingToken: tokens.hct,
    earningToken: tokens.usdt,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 6,
    stakingToken: tokens.hct,
    earningToken: tokens.xava,
    contractAddress: {
      43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools
