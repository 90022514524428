import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@hurricaneswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'GTText', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  
  #root > #menu{
    margin: auto;
    width: 100%;
    max-width: 1200px;
  }
  #menu > div{
     width: 100%;
    max-width: 1200px;
  }
  
   #menu > div > div > div{
     margin-left: auto!important;
     // margin-right: auto!important;
  }
`

export default GlobalStyle
