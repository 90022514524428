import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { Flex, Text, Skeleton } from '@hurricaneswap/uikit'
import { Farm } from 'state/types'
import { provider as ProviderType } from 'web3-core'
import { useTranslation } from 'contexts/Localization'
import ExpandableSectionButton from 'components/ExpandableSectionButton'
import { BASE_ADD_LIQUIDITY_URL, BASE_SCAN_URL } from "config";
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import DetailsSection from './DetailsSection'
import CardHeading from './CardHeading'
import CardActionsContainer from './CardActionsContainer'

export interface FarmWithStakedValue extends Farm {
  apr?: number
  liquidity?: BigNumber
}

/* const AccentGradient = keyframes`  
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
` */


const FCard = styled.div<{ isPromotedFarm: boolean }>`
  align-self: baseline;
  // background: ${(props) => props.theme.card.background};
    background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,0.6)'};

  border-radius: ${({ theme, isPromotedFarm }) => (isPromotedFarm ? '12px' : '12px')};
  box-shadow: rgba(159, 56, 140, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 32px;
  position: relative;
  text-align: center;
`

const Divider = styled.div`
  background-color: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.12)' : '#E4E4EB'};
  height: 1px;
  margin: 28px auto;
  margin-left:-24px;
  margin-right:-24px;
`
const StyleText = styled(Text)`
  color: ${({theme}) => theme.isDark ? 'rgba(255, 255, 255, 0.65)' :'#4D3C55'}
`

const ExpandingWrapper = styled.div<{ expanded: boolean }>`
  height: ${(props) => (props.expanded ? '100%' : '0px')};
  overflow: hidden;
`

interface FarmCardProps {
  farm: FarmWithStakedValue
  removed: boolean
  cakePrice?: BigNumber
  provider?: ProviderType
  account?: string
}

const FarmCard: React.FC<FarmCardProps> = ({ farm, removed, cakePrice, account }) => {

  const { t } = useTranslation()

  const [showExpandableSection, setShowExpandableSection] = useState(false)

  // We assume the token name is coin pair + lp e.g. CAKE-AVAX LP, LINK-AVAX LP,
  // NAR-CAKE LP. The images should be cake-bnb.svg, link-bnb.svg, nar-cake.svg
  // const farmImage = farm.lpSymbol.split(' ')[0].toLocaleLowerCase()
  const farmImage = farm?.quoteToken?.logoURI
  const secondFarmImage = farm?.token?.logoURI
  const isPool = farm?.isPool
  const lpLogo = farm?.lpLogo
  const totalValueFormatted = farm.liquidity
    ? `$${farm.liquidity.toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
    : '-'

  const lpLabel = farm.lpSymbol && farm.lpSymbol.replace('HURRICANE', '')
  const earnLabel = farm.dual ? farm.dual.earnLabel : 'HCT'

  const farmAPR = farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2 })

  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: farm.quoteToken.address,
    tokenAddress: farm.token.address,
  })
  let addLiquidityUrl 
  if (lpLabel === 'xHCT'){
    addLiquidityUrl = `https://hurricaneswap.com/#/stake/xhct`
  }else if (lpLabel === 'sHCT'){
      addLiquidityUrl = `https://hurricaneswap.com/#/stake/shct`
  }else if (lpLabel === 'WAVAX'){
    addLiquidityUrl = `https://hurricaneswap.com/#/swap`
  }else if (lpLabel.includes('bHc-')){
    addLiquidityUrl = `https://hurricaneswap.com/#/deposit`
  }else{
    addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
  }
 // const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
 // console.log(lpLabel)
  const lpAddress = farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]
  const isPromotedFarm = farm.token.symbol === 'HCT'

  return (
    <FCard isPromotedFarm={isPromotedFarm}>
      {/* {isPromotedFarm && <StyledCardAccent />} */}
      <CardHeading
        lpLabel={lpLabel}
        multiplier={farm.multiplier}
        isCommunityFarm={farm.isCommunity}
        farmImage={farmImage}
        secondFarmImage={secondFarmImage}
        tokenSymbol={farm.token.symbol}
        chain={farm.chain}
        isPool={isPool}
        lpLogo={lpLogo}
      />
      <Flex justifyContent="space-between" mb='8px'>
        <StyleText>{t('Status')}:</StyleText>
        {/* 之后可能还会加pid 什么的限制条件  */}
        <Text bold>{farm.multiplier === '0X' ? 'Finished' : 'Live'}</Text>
      </Flex>
      {!removed && (
        <Flex justifyContent="space-between" alignItems="center">
          <StyleText>{t('APR')}:</StyleText>
          <Text bold style={{ display: 'flex', alignItems: 'center' }}>
            {farm.apr ? (
              <>
               {/* <ApyButton lpLabel={lpLabel} addLiquidityUrl={addLiquidityUrl} cakePrice={cakePrice} apr={farm.apr} /> */}
                {farmAPR}%
              </>
            ) : (
              <Skeleton height={24} width={80} />
            )}
          </Text>
        </Flex>
      )}
     {/* <Flex justifyContent="space-between">
        <Text>{t('Earn')}:</Text>
        <Text bold>{earnLabel}</Text>
        </Flex>  */}
      <CardActionsContainer farm={farm} account={account} addLiquidityUrl={addLiquidityUrl} />
      <Divider />
      <ExpandableSectionButton
        onClick={() => setShowExpandableSection(!showExpandableSection)}
        expanded={showExpandableSection}
      />
      <ExpandingWrapper expanded={showExpandableSection}>
        <DetailsSection
          removed={removed}
          bscScanAddress={`${BASE_SCAN_URL}/address/${farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]}`}
          infoAddress='https://cchain.explorer.avax.network'
          totalValueFormatted={totalValueFormatted}
          lpLabel={lpLabel}
          addLiquidityUrl={addLiquidityUrl}
        />
      </ExpandingWrapper>
    </FCard>
  )
}

export default FarmCard
